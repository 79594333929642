<script setup></script>

<template>
  <div class="p-3 h-screen bg-[#f1f4fa] select-none">
    <div class="md:gap-28 gap-16 rounded-3xl h-full p-4 bg-white">
      <div class="grid grid-cols-3 gap-3 h-full overflow-auto">
        <div class="col-span-3 lg:col-span-1 md:col-span-1 sm:col-span-3 h-full">
          <GetStartedHeader />
          <!-- Left Side Column -->
          <div
            class="h-full lg:px-20 xl:px-24 md:px-30 px-4 py-10 sm:py-10 md:py-4 lg:py-4 flex items-center sm:justify-center relative"
          >
            <slot />
          </div>
        </div>
        <!-- Right Side Column -->
        <div
          class="col-span-3 lg:col-span-2 md:col-span-2 sm:col-span-3 bg-primary rounded-3xl text-white h-full relative"
        >
          <GetStartedRightSideCol />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
