<script setup></script>
<template>
  <div class="flex items-center h-full py-16 lg:px-16 md:px-10 sm:px-8 px-8">
    <div>
      <h1 class="text-2xl font-medium text-left">
        The Simplest way to manage your links.
      </h1>
      <p class="text-left">Join the Link Management Revolution</p>
      <div
        class="relative mx-auto overflow-hidden py-8 px-0 lg:px-8 md:px-5 sm:px-0 select-none"
      >
        <NuxtImg
          provider="awsS3"
          class="rounded-xl pointer-events-none"
          src="/dashboard-features.webp"
          alt="dashboard-image"
        />
      </div>
    </div>
    <GetStartedFooter />
  </div>
</template>

<style></style>
