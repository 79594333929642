<script setup></script>

<template>
  <!-- logo -->
  <NuxtLink to="/">
    <NuxtImg
      provider="awsS3"
      src="/logo.png"
      class="h-6 z-30 select-none lg:h-8 md:h-8 sm:h-6 absolute top-[3.5%] left-[7%] lg:top-[5%] md:top-[4%] sm:top-[3%] lg:left-[3%] md:left-[3%] sm:left-[4%]"
      alt="Smartlnks.com"
      data-not-lazy
    />
  </NuxtLink>
</template>

<style></style>
